
import { Options, Vue } from 'vue-class-component';
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';
import 'videojs-contrib-hls';

@Options({
  props: {
  }
})
export default class LiveStreamVideo extends Vue {

    public online = false;
    public bought = false;
    public player: any;
    public interval: any;
  $session: any;

    public mounted() {


        // JavaScript fetch api
        fetch( '/api/streaminfo', {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if(data.stream.state == 'OFFLINE') {
                    this.online = false;
                } else {
                    this.online = true;

                    this.player = videojs('video', undefined, () => {
                        this.player.src({
                            src: 'https://957c9f65964e.eu-west-1.playback.live-video.net/api/video/v1/eu-west-1.602018530498.channel.Auiepm6W0i26.m3u8',
                            type: 'application/x-mpegURL',
                        });
                    });
                    
                }
            })
            .catch(error => {
                console.log(error);
            });

            this.interval = setInterval( this.currentBoughtSession, 500 );
    }
    
    private currentBoughtSession() {
        if( this.$session.streams.length && this.$session.streams.length > 0 ) {
            
            let currentTimeStamp = Math.round( new Date().getTime() /1000 );
            let session = this.$session.streams.find( (stream: any)  => {
                return currentTimeStamp >= stream.from - 3600 && currentTimeStamp <= stream.to + 3600 && ( stream.hasProduct || stream.hasSubscription );
            }
            );

            if(session) {
                this.bought = true;
                this.interval.clearInterval();
            } else {
                if( this.$session.streams[0].hasSubscription) {
                    this.bought = true;
                    return;
                }
                this.bought = false;
            }
        }

    }

}
