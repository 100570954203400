
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class Login extends Vue {

    public email = '';
    public password = '';
    $local: any;

    public mounted() {
        if( this.$local.access_token && this.$local.access_token.length > 0 ) {
            this.$router.push('/streaming');
        } else if( (new URLSearchParams( window.location.search )).get('access_token') ) {
            this.$local.access_token = (new URLSearchParams( window.location.search )).get('access_token');
            this.$router.push('/streaming');
        }
    }

    public login() {

        fetch('https://yogaga.de/wp-json/yogaga/v1/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                grant_type: 'password',
                email: this.email,
                password: this.password,
            }),
        }).then(response => response.json())
            .then(data => {
                if (data.access_token) {
                    this.$local.access_token = data.access_token;
                    this.$router.push('/streaming');
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

}
